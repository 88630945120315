import Headroom from 'headroom.js'

const header = document.getElementById('header')
const burgerIcon = document.getElementById('burger__icon')

function initHeadroom(element) {
  if (element) {
    const headroom = new Headroom(element, {
      offset: 80,
      tolerance: 0,
      onPin: function() {},
      onUnpin: function() {},
    })
    headroom.init()
  }
}

initHeadroom(header)
initHeadroom(burgerIcon)